<template>
	<main>
		<div class="container lg:max-w-5xl h-full">
			<iframe
				v-if="url && url.includes('youtu')"
				:src="url"
				style="display: block; width: 100%; height: 56.25%"
				width="100%"
				height="56.25%"
			/>
			<img
				v-else-if="previewMode"
				:src="url"
				style="display: block; max-width: 100%; max-height: 100%"
			/>
			<iframe
				v-else
				:src="url"
				style="display: block; width: 100%; height: 100%"
				width="100%"
				height="100%"
			/>
		</div>
	</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'LinkBrowser',
	props: {
		url: { type: String, required: true },
		previewMode: { type: Boolean, required: false, default: false }
	}
});
</script>
